<template>
  <b-card
    border-variant="light"
    class="custom-shadow mb-75"
  >
    <div class="d-flex flex-column h-100">
      <div class="d-flex flex-wrap justify-content-between">
        <div>
          <small class="text-muted">{{ itemData.no }}</small>
          <router-link :to="{ name: 'item-detail', params: {id: itemData.id.toString()} }">
            <h6 class="mt-25 mb-1 text-primary">
              {{ itemData.name }}
            </h6>
          </router-link>
        </div>
        <swatch-card
          v-if="itemData.color"
          :color="itemData.color"
          class="mb-1"
        />
      </div>
      <div class="d-flex flex-wrap justify-content-between mt-auto">
        <div class="mb-1">
          <small class="text-muted">Harga Item</small>
          <h5 class="m-0 font-weight-bolder">
            {{ price }}
          </h5>
        </div>
        <div v-if="itemData.type && itemData.type.id === 1">
          <div class="d-flex justify-content-end">
            <b-card
              no-body
              border-variant="light"
              class="m-0 p-50 mr-50"
            >
              <small class="text-muted d-block">Coat</small>
              <small class="font-weight-bolder">{{ itemData.coat }}</small>
            </b-card>
            <b-card
              no-body
              border-variant="light"
              class="m-0 p-50 mr-50"
            >
              <small class="text-muted d-block">DFT</small>
              <small class="font-weight-bolder">{{ itemData.dry_film_thickness }}</small>
            </b-card>
            <b-card
              no-body
              border-variant="light"
              class="m-0 p-50"
            >
              <small class="text-muted d-block">Loss</small>
              <small class="font-weight-bolder">{{ itemData.loss }}</small>
            </b-card>
            <b-card
              v-if="itemData.data_sheet"
              no-body
              border-variant="light"
              class="m-0 p-50 ml-50 cursor-pointer"
              @click="$refs.pdf.show(itemData.data_sheet)"
            >
              <b-img
                :src="require('@/assets/images/icons/pdf.png')"
                height="auto"
                width="24"
              />
            </b-card>
          </div>
        </div>
      </div>
    </div>

    <pdf-preview
      ref="pdf"
    />
  </b-card>
</template>

<script>
import { BCard, BImg } from 'bootstrap-vue'
import SwatchCard from '@/components/misc/SwatchCard.vue'
import PdfPreview from '@/components/misc/PdfPreview.vue'

export default {
  components: {
    BCard,
    BImg,
    SwatchCard,
    PdfPreview,
  },
  props: {
    itemData: {
      type: Object,
      default: () => {},
    },
    price: {
      type: [Number, String],
      default: 0,
    },
  },
}
</script>

<style scoped>
.custom-shadow {
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 20px !important;
}
</style>
