<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center"
    style="height: 70vh"
  >
    <h3 class="text-muted d-block mb-1 mt-5 font-weight-normal">
      Terjadi Kesalahan!
    </h3>
    <h6
      class="text-muted mb-1"
      v-text="error"
    />
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="outline-primary"
      @click="hasHistory() ? $router.back() : $router.push('/')"
    >
      Kembali
    </b-button>
    <Lottie
      v-if="error.includes('429')"
      class="w-50"
      :options="{
        animationData: toomanyrequest,
      }"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Lottie from 'vue-lottie'
import toomanyrequest from '@/assets/images/lottie/toomanyrequest.json'

export default {
  components: {
    BButton,
    Lottie,
  },
  props: {
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      toomanyrequest,
    }
  },
  methods: {
    hasHistory() {
      return window.history.length > 2
    },
  },
}
</script>
