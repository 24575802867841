<template>
  <div class="d-flex align-items-center">
    <div
      v-b-popover.hover.top="`#${color.hex_code}`"
      class="colorswatch mr-50"
      :style="`background-color: #${color.hex_code};`"
    />
    <div>
      <h6 class="m-0">
        {{ color.eng_name }}
      </h6>
      <small>{{ color.id_ral }}</small>
    </div>
  </div>
</template>

<script>
import { VBPopover } from 'bootstrap-vue'

export default {
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    color: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style>
.colorswatch {
  height: 36px;
  width: 36px;
  border-radius: 4px;
}
</style>
