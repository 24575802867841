<template>
  <div
    class="w-100 p-1 d-flex flex-column justify-content-center align-items-center"
    style="height: 40vh"
  >
    <Lottie
      :options="{
        animationData: noresult,
      }"
    />
    <h5 class="text-muted font-weight-normal my-1">
      Belum ada data 😞
    </h5>
  </div>
</template>

<script>
import Lottie from 'vue-lottie'
import noresult from '@/assets/images/lottie/noresult.json'

export default {
  components: {
    Lottie,
  },
  data() {
    return {
      noresult,
    }
  },
}
</script>

<style>

</style>
