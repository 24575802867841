<template>
  <XyzTransition
    appear
    xyz="fade down-3 delay-2"
  >
    <div class="d-flex align-items-center mb-1">
      <feather-icon
        icon="ArrowLeftIcon"
        size="24"
        class="align-middle text-primary mr-2 mb-50 cursor-pointer"
        @click="preventBack ? $emit('preventBack') : $router.go(-1)"
      />
      <h3 class="d-none d-sm-block">
        {{ title }}
      </h3>
      <slot
        name="action"
        class="ml-auto"
      />
    </div>
  </XyzTransition>
</template>

<script>
export default {
  props: {
    preventBack: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style>

</style>
