<template>
  <div>
    <div
      v-if="loading !== 'error'"
    >
      <back-navigation title="Detail Scheme" />
      <div
        v-if="loading"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div
        v-else
        key="2"
      >
        <XyzTransitionGroup
          appear
          class="item-group"
          xyz="fade stagger-2 left-3"
          mode="out-in"
        >
          <b-card key="1">
            <div class="d-flex align-items-start">
              <div class="d-flex align-items-start flex-wrap">
                <div
                  v-if="schemeData.color"
                  class="customswatch mr-1 mb-1"
                  :style="`background-color: #${schemeData.color.hex_code};`"
                />
                <div>
                  <b-badge
                    class="mb-50 mt-50"
                    :variant="schemeData.status == 1 ? 'light-success' : 'light-secondary'"
                  >
                    {{ schemeData.status == 1 ? 'Aktif' : 'Tidak Aktif' }}
                  </b-badge>
                  <h4 class="font-weight-bolder">
                    {{ schemeData.name }}
                  </h4>
                  <h6
                    v-if="schemeData.notes"
                    class="text-secondary font-weight-normal m-0"
                  >
                    Notes : {{ schemeData.notes }}
                  </h6>
                </div>
              </div>
              <b-dropdown
                right
                class="ml-auto"
                variant="link"
                toggle-class="text-decoration-none p-0"
                no-caret
              >
                <template #button-content>
                  <b-avatar
                    class="m-0 p-0"
                    variant="light-secondary"
                    rounded
                  >
                    <feather-icon
                      icon="MoreHorizontalIcon"
                      size="20"
                    />
                  </b-avatar>
                </template>

                <b-dropdown-item
                  :disabled="!permission.schemeEdit"
                  :to="{ name: 'scheme-edit', params: { id: id.toString() } }"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                    :class="permission.schemeEdit ? 'text-warning' : 'text-muted'"
                  />
                  <span :class="permission.schemeEdit ? 'text-warning' : 'text-muted'">Edit Scheme</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="!permission.schemeEdit"
                  :to="{ name: 'scheme-copy', params: {id: id.toString()}}"
                >
                  <feather-icon
                    icon="CopyIcon"
                    class="mr-50"
                    :class="permission.schemeEdit ? 'text-info' : 'text-muted'"
                  />
                  <span :class="permission.schemeEdit ? 'text-info' : 'text-muted'">Copy Scheme</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="schemeData.status === 0 || !permission.schemeDelete"
                  @click="deleteScheme()"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                    :class="schemeData.status === 1 && permission.schemeDelete ? 'text-danger' : 'text-muted'"
                  />
                  <span :class="schemeData.status === 1 && permission.schemeDelete ? 'text-danger' : 'text-muted'">Hapus Scheme</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-card>
          <b-card key="2">
            <XyzTransition
              appear
              xyz="fade up-3 delay-7"
            >
              <b-row align-v="start">
                <b-col
                  cols="12"
                  md="5"
                  class="mb-1 align-self-center"
                >
                  <h5 class="m-0">
                    List Item
                  </h5>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="ml-0 ml-md-auto mb-1 pr-md-0"
                >
                  <b-form-radio-group
                    v-model="itemView"
                    class="list item-view-radio-group w-100"
                    buttons
                    size="sm"
                    button-variant="outline-primary"
                  >
                    <b-form-radio
                      value="grid"
                    >
                      <feather-icon
                        icon="GridIcon"
                        size="21"
                      />
                    </b-form-radio>
                    <b-form-radio
                      value="table"
                    >
                      <feather-icon
                        icon="ListIcon"
                        size="21"
                      />
                    </b-form-radio>
                  </b-form-radio-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  xl="3"
                  class="ml-auto ml-md-0 mb-50"
                >
                  <v-select
                    v-model="selectedPriceCategory"
                    placeholder="Pilih Harga"
                    :options="priceCategory"
                    label="name"
                    :clearable="false"
                  />
                </b-col>
              </b-row>
            </XyzTransition>
            <hr>
            <div v-if="itemView == 'grid'">
              <XyzTransitionGroup
                appear
                class="item-group match-height row"
                xyz="fade stagger-2 down-3 delay-2"
                mode="out-in"
              >
                <b-col
                  v-for="(item, indexItem) in schemeData.items"
                  :key="indexItem"
                  class="mb-1"
                  cols="12"
                  xl="4"
                >
                  <item-card
                    :key="indexItem"
                    :item-data="item"
                    :price="getPrice(item.detailSellingPrice)"
                  />
                </b-col>
              </XyzTransitionGroup>
            </div>
            <div v-else>
              <XyzTransition
                appear
                xyz="fade down-3"
                mode="in-out"
              >
                <vue-good-table
                  :columns="tableColumn"
                  :rows="schemeData.items"
                  :sort-options="{ enabled: false }"
                  :pagination-options="{
                    enabled: false,
                  }"
                  style-class="vgt-table"
                >
                  <empty-state-table slot="emptystate" />
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'name'">
                      <router-link :to="{ name: 'item-detail', params: {id: props.row.id.toString()} }">
                        <h6 class="text-primary m-0">
                          {{ props.row.name }}
                        </h6>
                      </router-link>
                      <small class="text-secondary">
                        {{ props.row.no }}
                      </small>
                    </span>
                    <span v-else-if="props.column.field === 'color'">
                      <swatch-card
                        v-if="props.row.color"
                        :color="props.row.color"
                        class="mb-1"
                      />
                    </span>
                    <span v-else-if="props.column.field === 'coat'">
                      {{ props.row.coat }}
                    </span>
                    <span v-else-if="props.column.field === 'dft'">
                      {{ props.row.dry_film_thickness }}
                    </span>
                    <span v-else-if="props.column.field === 'loss'">
                      {{ props.row.loss }}
                    </span>
                    <span v-else-if="props.column.field === 'price'">
                      {{ getPrice(props.row.detailSellingPrice) }}
                    </span>
                    <span v-else-if="props.column.field === 'data_sheet'">
                      <b-button
                        v-if="props.row.data_sheet"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="outline-danger"
                        class="w-50"
                        @click="$refs.pdf.show(props.row.data_sheet)"
                      >
                        <b-img
                          :src="require('@/assets/images/icons/pdf.png')"
                          height="auto"
                          width="18"
                        />
                      </b-button>
                    </span>
                  </template>
                </vue-good-table>
              </XyzTransition>
            </div>
          </b-card>
        </XyzTransitionGroup>
      </div>
    </div>
    <error-endpoint
      v-else
      :key="error"
      :error="error"
    />

    <pdf-preview
      ref="pdf"
    />
  </div>
</template>

<script>
import BackNavigation from '@/components/misc/BackNavigation.vue'
import ErrorEndpoint from '@/components/misc/ErrorEndpoint.vue'
import {
  BCard, BBadge, BAvatar, BSpinner, BDropdown, BDropdownItem, BRow, BCol,
  BFormRadioGroup, BFormRadio, BImg, BButton,
} from 'bootstrap-vue'
import ItemCard from '@/components/misc/ItemCard.vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import SwatchCard from '@/components/misc/SwatchCard.vue'
import EmptyStateTable from '@/components/misc/EmptyStateTable.vue'
import vSelect from 'vue-select'
import { formatCurrency } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PdfPreview from '@/components/misc/PdfPreview.vue'

export default {
  components: {
    BackNavigation,
    BCard,
    BBadge,
    BAvatar,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BFormRadio,
    BFormRadioGroup,
    BImg,
    BButton,
    ItemCard,
    VueGoodTable,
    SwatchCard,
    ErrorEndpoint,
    EmptyStateTable,
    vSelect,
    PdfPreview,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formatCurrency,
      itemView: 'grid',
      loading: true,
      error: '',
      schemeData: '',
      selectedPriceCategory: '',
      tableColumn: [
        {
          label: 'Item',
          field: 'name',
          width: '280px',
        },
        {
          label: 'Warna',
          field: 'color',
          width: '280px',
        },
        {
          label: 'VS',
          field: 'vs_volume_solid',
        },
        {
          label: 'Coat',
          field: 'coat',
        },
        {
          label: 'DFT',
          field: 'dft',
        },
        {
          label: 'Loss',
          field: 'loss',
        },
        {
          label: 'Harga',
          field: 'price',
        },
        {
          label: 'Data Sheet',
          field: 'data_sheet',
          width: '160px',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      priceCategory: 'getPriceCategoryCustomer',
      permission: 'getUserPermission',
    }),
  },
  async mounted() {
    if (this.priceCategory.length < 1) {
      await this.$store.dispatch('getPriceCategory')
    }
    await this.getSchemeDetail(Number(this.id)).then(async data => {
      if (data.reference_db !== this.$store.state.reference.selectedDB.id) {
        this.loading = 'error'
        this.error = 'Reference DB tidak sesuai.'
      } else {
        this.schemeData = data
        this.loading = false
      }
    }).catch(error => {
      this.loading = 'error'
      this.error = `${error.graphQLErrors[0].message}`
    })
  },
  methods: {
    ...mapActions(['getSchemeDetail', 'getItemsDetail']),
    getPrice(harga) {
      if (this.priceCategory.length && !this.selectedPriceCategory) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedPriceCategory = this.priceCategory[this.priceCategory.length - 1]
      }

      if (this.selectedPriceCategory) {
        const { price } = harga.filter(el => el.priceCategory.id === this.selectedPriceCategory.id)[0]
        return this.formatCurrency(price)
      }

      return this.formatCurrency(harga[0].price)
    },
    deleteScheme() {
      this.$swal({
        title: 'Hapus Data Scheme?',
        text: 'Konfirmasi jika anda ingin menghapus data scheme',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('deleteScheme', this.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil menghapus data scheme',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
            this.$store.dispatch('getScheme')
            this.$router.push('/scheme')
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal menghapus data scheme',
                icon: 'TrashIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.divider {
  width: 100%;
  height: 6px;
  border-radius: 2px;
}
.customswatch{
  height: 80px;
  width: 80px;
  border-radius: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
